import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import axios from 'axios';
import './Validadores.css';

function Validadores() {
  const [numero, setNumero] = useState('');
  const [iccid, setIccid] = useState('');
  const [tipo, setTipo] = useState('');
  const [lote, setLote] = useState('');
  const [validador, setValidador] = useState('');
  const [data, setData] = useState([]);
  const [validadores, setValidadores] = useState([]);
  const [filterLote, setFilterLote] = useState('');
  const [filterColocado, setFilterColocado] = useState('');
  const [loteOptions, setLoteOptions] = useState([]);
  const [colocadoOptions, setColocadoOptions] = useState([]);

  useEffect(() => {
    fetchValidadores();
    fetchData();
    fetchFilterOptions();
  }, []);

  const fetchValidadores = async () => {
    try {
      // const response = await axios.get('http://localhost/backend/validadores/get_validadores.php');
      const response = await axios.get('https://staff.smci.com.mx/backend/validadores/get_validadores.php');
      setValidadores(response.data);
    } catch (error) {
      console.error('Error fetching validadores:', error);
    }
  };

  const fetchData = async () => {
    try {
      // const response = await axios.get('http://localhost/backend/validadores/get_chips.php');
      const response = await axios.get('https://staff.smci.com.mx/backend/validadores/get_chips.php');
      setData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchFilterOptions = async () => {
    try {
      // const loteResponse = await axios.get('http://localhost/backend/validadores/get_lote_options.php');
      const loteResponse = await axios.get('https://staff.smci.com.mx/backend/validadores/get_lote_options.php');
      setLoteOptions(loteResponse.data);

      // const colocadoResponse = await axios.get('http://localhost/backend/validadores/get_colocado_options.php');
      const colocadoResponse = await axios.get('https://staff.smci.com.mx/backend/validadores/get_colocado_options.php');
      setColocadoOptions(colocadoResponse.data);
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }
  };

  const handleUpdate = async () => {
    const colocado = JSON.parse(localStorage.getItem('usuarioData')).id;
    const updateData = {
      validador,
      iccid: iccid === 'Sin datos' ? '' : iccid,
      numero: numero === 'Sin datos' ? '' : numero,
      tipo: tipo === 'Sin datos' ? '' : tipo,
      lote: lote === 'Sin datos' ? '' : lote,
      colocado
    };

    console.log('Datos enviados para actualización:', updateData);

    try {
      // const response = await axios.post('http://localhost/backend/validadores/update_chip.php', updateData);
      const response = await axios.post('https://staff.smci.com.mx/backend/validadores/update_chip.php', updateData);
      console.log('Respuesta del servidor:', response.data);
      alert(response.data.message);
      fetchData();
    } catch (error) {
      console.error('Error updating chip:', error);
    }
  };

  const handleValidadorChange = async (e) => {
    const selectedValidador = e.target.value;
    setValidador(selectedValidador);

    try {
      // const response = await axios.get(`http://localhost/backend/validadores/get_chip.php?validador=${selectedValidador}`);
      const response = await axios.get(`https://staff.smci.com.mx/backend/validadores/get_chip.php?validador=${selectedValidador}`);
      const chip = response.data;
      setIccid(chip.iccid || '');
      setNumero(chip.numero || '');
      setTipo(chip.tipo || '');
      setLote(chip.lote || '');
    } catch (error) {
      console.error('Error fetching chip data:', error);
    }
  };

  const handleFilter = () => {
    fetchData(); // Fetch data again to reset any existing filters
  };

  const filteredData = data.filter(item => {
    const loteMatch = filterLote === '' || (item.lote !== null && item.lote !== undefined && item.lote.toString() === filterLote);
    const colocadoMatch = filterColocado === '' || item.colocado_nombre === filterColocado;
    return loteMatch && colocadoMatch;
  });

  return (
    <div className="validadores">
      <Navbar />
      <div className="container mt-5 pt-5 text-center">
        <div className="row mb-3 justify-content-center">
          <div className="col-md-2">
            <select className="form-select" value={validador} onChange={handleValidadorChange}>
              <option value="">Selecciona un validador</option>
              {validadores.map((val) => (
                <option key={val.validador} value={val.validador}>
                  {val.validador}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <input
              type="text"
              className="form-control"
              placeholder="ICCID..."
              value={iccid}
              onChange={(e) => setIccid(e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <input
              type="text"
              className="form-control"
              placeholder="Numero..."
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
            />
          </div>
        </div>
        <div className="row mb-3 justify-content-center">
          <div className="col-md-2">
            <select
              className="form-select"
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
            >
              <option value="Sin datos">Sin datos</option>
              <option value="Prepago">Prepago</option>
              <option value="Plan">Plan</option>
            </select>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              value={lote}
              onChange={(e) => setLote(e.target.value)}
            >
              <option value="Sin datos">Sin datos</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-primary w-100"
              onClick={handleUpdate}
            >
              Actualizar
            </button>
          </div>
        </div>
        <div className="row mb-3 justify-content-center separacion">
          <div className="col-md-2">
            <select className="form-select" value={filterLote} onChange={(e) => setFilterLote(e.target.value)}>
              <option value="">Todos los Lotes</option>
              {loteOptions.map((lote, index) => (
                <option key={index} value={lote}>{lote}</option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <select className="form-select" value={filterColocado} onChange={(e) => setFilterColocado(e.target.value)}>
              <option value="">Todo el Personal</option>
              {colocadoOptions.map((colocado, index) => (
                <option key={index} value={colocado}>{colocado}</option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            {/* <button
              className="btn btn-primary w-100"
              onClick={handleFilter}
            >
              Filtrar
            </button> */}
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-dark table-bordered">
            <thead>
              <tr>
                <th>V</th>
                <th>ICCID</th>
                <th>Numero</th>
                <th>Tipo</th>
                <th>L</th>
                <th>U</th>
                <th>Colocado</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{item.validador}</td>
                  <td>{item.iccid || ''}</td>
                  <td>{item.numero || ''}</td>
                  <td>{item.tipo || ''}</td>
                  <td>{item.lote !== null && item.lote !== undefined ? item.lote : ''}</td>
                  <td>{item.unidad || ''}</td>
                  <td>{item.colocado_nombre || ''}</td>
                  <td>{item.fecha ? new Date(item.fecha).toISOString().split('T')[0] : ''}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Validadores;
