import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import './Dashboard.css';
import logo from '../../images/logo.png';
import axios from 'axios';

function Dashboard() {
  const [usuarioData, setUsuarioData] = useState(null);

  useEffect(() => {
    const usuario = JSON.parse(localStorage.getItem('usuarioData'));
    if (usuario) {
      setUsuarioData(usuario);
    }
  }, []);

  useEffect(() => {
    console.log('Usuario Data:', usuarioData); // Añadir este log para depuración
  }, [usuarioData]);

  const handlePhotoChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', usuarioData.id);

    try {
      // const response = await axios.post('http://localhost/backend/usuarios/update_photo.php', formData, {
        const response = await axios.post('https://staff.smci.com.mx/backend/usuarios/update_photo.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data.message === "Foto actualizada correctamente") {
        const updatedUser = { ...usuarioData };
        updatedUser.foto = await toBase64(file);
        setUsuarioData(updatedUser);
        localStorage.setItem('usuarioData', JSON.stringify(updatedUser)); // Cambiar 'usuario' a 'usuarioData'
      }
    } catch (error) {
      console.error('Error al subir la foto:', error);
    }
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  };

  return (
    <div className="dashboard">
      <Navbar />
      <div className="container mt-5 pt-5">
        <div className="row mb-4">
          <div className="col-md-4">
            <div className="dashboard-card shadow-sm position-relative">
              <img
                src={usuarioData?.foto ? `data:image/jpeg;base64,${usuarioData.foto}` : logo}
                alt="Foto de Usuario"
                className="img-fluid profile-photo"
                onClick={() => document.getElementById('fileInput').click()}
                style={{ cursor: 'pointer' }}
              />
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                accept="image/png, image/jpeg, image/jpg"
                onChange={handlePhotoChange}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="dashboard-card shadow-sm">
              Proximamente...
            </div>
          </div>
          <div className="col-md-4">
            <div className="dashboard-card shadow-sm">
              Proximamente...
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="dashboard-card shadow-sm">
              {usuarioData ? (
                <>
                  Usuario: {usuarioData.usuario}<br />
                  Rol: {usuarioData.rol}<br />
                  {usuarioData.prefijo ? (
                    <>
                      Prefijo: {usuarioData.prefijo.abreviatura} ({usuarioData.prefijo.significado})
                    </>
                  ) : (
                    'Prefijo no disponible'
                  )}
                </>
              ) : (
                'Cargando...'
              )}
            </div>
          </div>
          <div className="col-md-8">
            <div className="dashboard-card shadow-sm">
              Proximamente...
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
