import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import './Monitoreo.css';

function Monitoreo() {
  const [reportes, setReportes] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [unidadSeleccionada, setUnidadSeleccionada] = useState('');
  const [validadorNuevo, setValidadorNuevo] = useState('');
  const [fechaSeleccionada, setFechaSeleccionada] = useState('');
  const [nuevasUnidades, setNuevasUnidades] = useState('');
  const [fechaRegistro, setFechaRegistro] = useState('');

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setFechaSeleccionada(today);
    setFechaRegistro(today);
    fetchReportes(today);
    fetchUnidades();
  }, []);

  useEffect(() => {
    if (fechaSeleccionada) {
      fetchReportes(fechaSeleccionada);
    }
  }, [fechaSeleccionada]);

  const fetchReportes = async (fecha) => {
    try {
      // const response = await axios.get(`http://localhost/backend/monitoreo/get_reportes.php?fecha=${fecha}`);
      const response = await axios.get(`https://staff.smci.com.mx/backend/monitoreo/get_reportes.php?fecha=${fecha}`);
      const sortedReportes = sortReportes(response.data);
      setReportes(sortedReportes);
    } catch (error) {
      console.error('Error fetching reportes:', error);
    }
  };

  const fetchUnidades = async () => {
    try {
      // const response = await axios.get('http://localhost/backend/monitoreo/get_unidades.php');
      const response = await axios.get('https://staff.smci.com.mx/backend/monitoreo/get_unidades.php');
      setUnidades(response.data);
    } catch (error) {
      console.error('Error fetching unidades:', error);
    }
  };

  const sortReportes = (reportes) => {
    const order = { 'N': 1, 'S': 2, 'P': 3, 'T': 4 };
    return reportes.sort((a, b) => order[a.estado] - order[b.estado]);
  };

  const handleRegistrar = async () => {
    const unidades = nuevasUnidades.split(',').map(unidad => unidad.trim());
    const data = { unidades, fecha: fechaRegistro };

    try {
      // await axios.post('http://localhost/backend/monitoreo/registrar_unidades.php', data);
      await axios.post('https://staff.smci.com.mx/backend/monitoreo/registrar_unidades.php', data);
      fetchReportes(fechaRegistro);
    } catch (error) {
      console.error('Error registering unidades:', error);
    }
  };

  const handleActualizar = async () => {
    try {
      const data = { reportes };
      console.log('Datos enviados para actualización:', data); // Verificación
      // const response = await axios.post('http://localhost/backend/monitoreo/actualizar_reportes.php', data);
      const response = await axios.post('https://staff.smci.com.mx/backend/monitoreo/actualizar_reportes.php', data);
      console.log('Respuesta del servidor:', response.data); // Verificación
      fetchReportes(fechaSeleccionada);
    } catch (error) {
      console.error('Error updating reportes:', error);
    }
  };

  const handleReporteChange = (index, value) => {
    const newReportes = [...reportes];
    newReportes[index].reporte = value;
    setReportes(newReportes);
  };

  const handleDiagnosticoChange = (index, value) => {
    const newReportes = [...reportes];
    newReportes[index].diagnostico = value;
    setReportes(newReportes);
  };

  const handleEstadoChange = (index, value) => {
    const newReportes = [...reportes];
    newReportes[index].estado = value;
    const sortedReportes = sortReportes(newReportes);
    setReportes(sortedReportes);
  };

  const handleValidadorChange = async () => {
    try {
      const data = { unidad: unidadSeleccionada, validador_nuevo: validadorNuevo };
      // const response = await axios.post('http://localhost/backend/monitoreo/actualizar_validador.php', data);
      const response = await axios.post('https://staff.smci.com.mx/backend/monitoreo/actualizar_validador.php', data);
      console.log('Respuesta del servidor:', response.data); // Verificación
      if (response.data.message.includes('El validador ya está asignado a la unidad')) {
        alert(response.data.message);
      } else {
        setUnidadSeleccionada('');
        setValidadorNuevo('');
        fetchUnidades();
        fetchReportes(fechaSeleccionada);
      }
    } catch (error) {
      console.error('Error updating validador:', error);
    }
  };

  const getReporteColor = (value) => {
    switch (value) {
      case 'D':
        return 'red';
      case 'G':
        return 'gray';
      case 'I':
        return 'pink';
      default:
        return 'white';
    }
  };

  const getEstadoColor = (value) => {
    switch (value) {
      case 'S':
        return 'green';
      case 'N':
        return 'red';
      case 'T':
        return 'orange';
      case 'P':
        return 'yellow';
      default:
        return 'white';
    }
  };

  return (
    <div className="monitoreo">
      <Navbar />
      <div className="container mt-5 pt-5">
        <div className="row mb-3 justify-content-center">
          <div className="col-md-3">
            <input 
              type="text" 
              className="form-control mb-2" 
              placeholder="Registrar unidades (12,15,20,50...)" 
              value={nuevasUnidades}
              onChange={(e) => setNuevasUnidades(e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <input 
              type="date" 
              className="form-control mb-2" 
              value={fechaRegistro}
              onChange={(e) => setFechaRegistro(e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <button className="btn btn-primary w-100 mb-2" onClick={handleRegistrar}>Registrar</button>
          </div>
        </div>
        <div className="row mb-3 justify-content-center">
          <div className="col-md-3">
            <input 
              type="date" 
              className="form-control mb-2"
              value={fechaSeleccionada}
              onChange={(e) => setFechaSeleccionada(e.target.value)}
            />
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-dark table-bordered">
            <thead>
              <tr>
                <th>Unidad</th>
                <th>Validador</th>
                <th>Reporte</th>
                <th>Diagnostico</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {reportes.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.unidad}</td>
                  <td>{item.validador}</td>
                  <td>
                    <select
                      className="form-select"
                      value={item.reporte}
                      onChange={(e) => handleReporteChange(index, e.target.value)}
                      style={{ color: getReporteColor(item.reporte) }}
                    >
                      <option value="I">I</option>
                      <option value="G">G</option>
                      <option value="D">D</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={item.diagnostico}
                      onChange={(e) => handleDiagnosticoChange(index, e.target.value)}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      value={item.estado}
                      onChange={(e) => handleEstadoChange(index, e.target.value)}
                      style={{ color: getEstadoColor(item.estado) }}
                    >
                      <option value="S">S</option>
                      <option value="N">N</option>
                      <option value="T">T</option>
                      <option value="P">P</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 text-center">
          <button className="btn btn-success" onClick={handleActualizar}>Actualizar</button>
        </div>
        <div className="mt-4">
          <h5 className="text-center">Cambiar validador</h5>
          <div className="row mb-3 justify-content-center">
            <div className="col-md-2">
              <select 
                className="form-select"
                value={unidadSeleccionada}
                onChange={(e) => setUnidadSeleccionada(e.target.value)}
              >
                <option value="">Seleccionar unidad</option>
                {unidades.map((unidad) => (
                  <option key={unidad.unidad} value={unidad.unidad}>{unidad.unidad}</option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <input 
                type="number" 
                className="form-control" 
                placeholder="Nuevo validador"
                value={validadorNuevo}
                onChange={(e) => setValidadorNuevo(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={handleValidadorChange}>Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Monitoreo;
