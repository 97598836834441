import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import './Bitacora.css';
import moment from 'moment-timezone';

function Bitacora() {
  const [usuario, setUsuario] = useState('');
  const [usuarioId, setUsuarioId] = useState('');
  const [proyectos, setProyectos] = useState([]);
  const [proyectoId, setProyectoId] = useState('');
  const [actividad, setActividad] = useState('');
  const [bitacora, setBitacora] = useState([]);

  useEffect(() => {
    const usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
    if (usuarioData) {
      setUsuario(usuarioData.usuario);
      setUsuarioId(usuarioData.id);
      fetchProyectos();
      fetchBitacora(usuarioData.id);
    } else {
      console.error('No user data found in localStorage.');
    }
  }, []);

  const fetchProyectos = async () => {
    try {
      // const response = await axios.get('http://localhost/backend/proyectos/crud.php');
      const response = await axios.get('https://staff.smci.com.mx/backend/proyectos/crud.php');
      setProyectos(response.data);
    } catch (error) {
      console.error('Error fetching proyectos:', error);
    }
  };

  const fetchBitacora = async (usuarioId) => {
    try {
      // const response = await axios.get(`http://localhost/backend/bitacora/crud.php?usuario_id=${usuarioId}`);
      const response = await axios.get(`https://staff.smci.com.mx/backend/bitacora/crud.php?usuario_id=${usuarioId}`);
      setBitacora(response.data);
    } catch (error) {
      console.error('Error fetching bitacora:', error);
    }
  };

  const handleRegister = async () => {
    try {
      const now = moment().tz('America/Mexico_City').format('YYYY-MM-DD HH:mm:ss');

      const data = {
        usuario_id: usuarioId,
        proyecto_id: proyectoId,
        actividad: actividad,
        fecha: now
      };

      // await axios.post('http://localhost/backend/bitacora/crud.php', data);
      await axios.post('https://staff.smci.com.mx/backend/bitacora/crud.php', data);
      fetchBitacora(usuarioId);
      setActividad('');
    } catch (error) {
      console.error('Error registering bitacora:', error);
    }
  };

  return (
    <div className="bitacora">
      <Navbar />
      <div className="container mt-5 pt-5">
        <div className="row mb-4">
          <div className="col-md-2">
            <div className="form-group">
              <input type="text" className="form-control" value={usuario} readOnly />
            </div>
            <div className="form-group">
              <select className="form-control" value={proyectoId} onChange={(e) => setProyectoId(e.target.value)}>
                <option value="">Selecciona un proyecto</option>
                {proyectos.map((proyecto) => (
                  <option key={proyecto.id} value={proyecto.id}>
                    {proyecto.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <button className="btn btn-primary" onClick={handleRegister}>Registrar</button>
            </div>
          </div>
          <div className="col-md-10">
            <div className="form-group">
              <textarea
                className="form-control"
                value={actividad}
                onChange={(e) => setActividad(e.target.value)}
                placeholder="Escribe tu actividad"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-dark table-striped table-bordered">
              <thead>
                <tr>
                  <th>Proyecto</th>
                  <th>Fecha</th>
                  <th>Actividad</th>
                </tr>
              </thead>
              <tbody>
                {bitacora.map((registro) => (
                  <tr key={registro.id}>
                    <td>{registro.proyecto_nombre}</td>
                    <td>{registro.fecha}</td>
                    <td>{registro.actividad}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bitacora;
