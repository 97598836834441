import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import Bitacora from './pages/bitacora/Bitacora';
import Monitoreo from './pages/monitoreo/Monitoreo';
import Auditoria from './pages/auditoria/Auditoria';
import Validadores from './pages/validadores/Validadores';
import Login from './pages/login/Login';
import './App.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/bitacora" element={<Bitacora />} />
        <Route path="/monitoreo" element={<Monitoreo />} />
        <Route path="/auditoria" element={<Auditoria />} />
        <Route path="/validadores" element={<Validadores />} />
      </Routes>
    </div>
  );
}

export default App;
