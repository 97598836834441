import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import axios from 'axios';
import './Login.css';
import logo from '../../images/logo.png';

function Login() {
  const [usuario, setUsuario] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // const response = await axios.post('http://localhost/backend/usuarios/login.php', {
        const response = await axios.post('https://staff.smci.com.mx/backend/usuarios/login.php', {
        usuario: usuario,
        contrasena: contrasena
      });

      if (response.data.message) {
        setError(response.data.message);
      } else {
        localStorage.setItem('usuarioData', JSON.stringify(response.data));
        // const permisosResponse = await axios.get(`http://localhost/backend/usuarios/get_permisos.php?user_id=${response.data.id}`);
        const permisosResponse = await axios.get(`https://staff.smci.com.mx/backend/usuarios/get_permisos.php?user_id=${response.data.id}`);
        localStorage.setItem('permisos', JSON.stringify(permisosResponse.data));
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 login-background">
      <div className="login-box card p-4 text-white">
        <div className="text-center mb-4">
          <img src={logo} alt="Logo" className="img-fluid login-logo" />
        </div>
        <div className="form-group mb-3">
          <input
            type="text"
            className="form-control login-input"
            placeholder="Usuario"
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
          />
        </div>
        <div className="form-group mb-3">
          <input
            type="password"
            className="form-control login-input"
            placeholder="Contraseña"
            value={contrasena}
            onChange={(e) => setContrasena(e.target.value)}
          />
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="d-flex justify-content-center">
          <button className="btn login-button" onClick={handleLogin}>
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
