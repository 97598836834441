import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import './Auditoria.css';
import axios from 'axios';
import logo from '../../images/smartbus_logo.png';

function Auditoria() {
  const [fecha, setFecha] = useState('');

  const handleGenerateReport = async () => {
    try {
      // const response = await axios.get(`http://localhost/backend/auditoria/generate_report.php?fecha=${fecha}`);
      const response = await axios.get(`https://staff.smci.com.mx/backend/auditoria/generate_report.php?fecha=${fecha}`);
      const reportData = response.data;
      const reportWindow = window.open('', '_blank');
      reportWindow.document.write(generateReportHTML(reportData, fecha));
      reportWindow.document.close();
    } catch (error) {
      console.error('Error generating report:', error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Ajuste de fecha
    return date.toLocaleDateString('es-ES', options);
  };

  const generateReportHTML = (data, fecha) => {
    const { desconectado, gps, intermitente, noSolucionado, taller, parado, unidadesDesconectadas } = data;

    // Dividir unidadesDesconectadas en dos partes
    const half = Math.ceil(unidadesDesconectadas.length / 2);
    const firstHalf = unidadesDesconectadas.slice(0, half);
    const secondHalf = unidadesDesconectadas.slice(half);

    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Reporte de Depuración</title>
        <style>
          body { font-family: Arial, sans-serif; padding: 20px; text-align: center; }
          .report-title { margin-bottom: 20px; }
          .report-table { border-collapse: collapse; margin: 0 auto 20px auto; }
          .report-table th, .report-table td { border: 1px solid #000; padding: 5px; text-align: center; }
          .report-table th { background-color: #f2f2f2; }
          .small-table { width: 50%; margin: 0 auto; }
          .double-table-container { display: flex; justify-content: center; margin-top: 20px; }
          .double-table { width: 45%; margin: 0 10px; }
          .header-container { display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px; }
          .header-container img { max-width: 150px; }
          .header-container h1 { flex-grow: 1; text-align: center; }
        </style>
      </head>
      <body>
        <div class="header-container">
          <img src="${logo}" alt="Logo" />
          <h1 class="report-title">Depuración - ${formatDate(fecha)}</h1>
        </div>
        <table class="report-table small-table">
          <thead>
            <tr>
              <th>Reporte</th>
              <th>Cant</th>
              <th>Estado</th>
              <th>Cant</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Desconectado</td>
              <td>${desconectado}</td>
              <td>No Solucionado</td>
              <td>${noSolucionado}</td>
            </tr>
            <tr>
              <td>GPS</td>
              <td>${gps}</td>
              <td>Taller</td>
              <td>${taller}</td>
            </tr>
            <tr>
              <td>Intermitente</td>
              <td>${intermitente}</td>
              <td>Parado</td>
              <td>${parado}</td>
            </tr>
          </tbody>
        </table>
        <h2 class="report-title">Unidades Desconectadas</h2>
        <div class="double-table-container">
          <table class="report-table double-table">
            <thead>
              <tr>
                <th>U</th>
                <th>V</th>
                <th>Diagnostico</th>
              </tr>
            </thead>
            <tbody>
              ${firstHalf.map((unidad) => `
                <tr>
                  <td>${unidad.unidad}</td>
                  <td>${unidad.validador}</td>
                  <td>${unidad.diagnostico}</td>
                </tr>`).join('')}
            </tbody>
          </table>
          <table class="report-table double-table">
            <thead>
              <tr>
                <th>U</th>
                <th>V</th>
                <th>Diagnostico</th>
              </tr>
            </thead>
            <tbody>
              ${secondHalf.map((unidad) => `
                <tr>
                  <td>${unidad.unidad}</td>
                  <td>${unidad.validador}</td>
                  <td>${unidad.diagnostico}</td>
                </tr>`).join('')}
            </tbody>
          </table>
        </div>
      </body>
      </html>
    `;
  };

  return (
    <div className="auditoria">
      <Navbar />
      <div className="container mt-5 pt-5">
        <div className="row mb-4 justify-content-center">
          <div className="col-md-3">
            <input 
              type="date" 
              className="form-control mb-2" 
              value={fecha}
              onChange={(e) => setFecha(e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <button 
              className="btn btn-primary w-100 mb-2"
              onClick={handleGenerateReport}
            >
              Generar Reporte
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auditoria;
