import React from 'react';
import { FaTachometerAlt, FaBook, FaTv, FaClipboardCheck, FaSimCard } from 'react-icons/fa';
import logo from '../images/logo.png';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import './Navbar.css';

function Navbar() {
  const navigate = useNavigate();
  const permisos = JSON.parse(localStorage.getItem('permisos') || '[]');

  const handleLogout = () => {
    localStorage.removeItem('usuarioData');
    localStorage.removeItem('permisos');
    navigate('/');
  };

  const iconMap = {
    Dashboard: { icon: <FaTachometerAlt className="nav-icon" data-tooltip-id="tooltip" data-tooltip-content="Dashboard" />, path: '/dashboard' },
    Bitacora: { icon: <FaBook className="nav-icon" data-tooltip-id="tooltip" data-tooltip-content="Bitacora" />, path: '/bitacora' },
    Monitoreo: { icon: <FaTv className="nav-icon" data-tooltip-id="tooltip" data-tooltip-content="Monitoreo" />, path: '/monitoreo' },
    Auditoria: { icon: <FaClipboardCheck className="nav-icon" data-tooltip-id="tooltip" data-tooltip-content="Auditoria" />, path: '/auditoria' },
    Validadores: { icon: <FaSimCard className="nav-icon" data-tooltip-id="tooltip" data-tooltip-content="Validadores" />, path: '/validadores' }
  };

  const icons = permisos.map(permiso => iconMap[permiso]);
  const midPoint = Math.ceil(icons.length / 2);
  const leftIcons = icons.slice(0, midPoint);
  const rightIcons = icons.slice(midPoint);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div className="container-fluid d-flex justify-content-center align-items-center">
        <ul className="navbar-nav d-flex align-items-center">
          {leftIcons.map((item, index) => (
            <li key={index} className="nav-item mx-2" onClick={() => navigate(item.path)}>
              {item.icon}
            </li>
          ))}
          <li className="nav-item mx-3" onClick={handleLogout}>
            <img src={logo} alt="Logo" className="navbar-logo" data-tooltip-id="tooltip" data-tooltip-content="Logout" />
          </li>
          {rightIcons.map((item, index) => (
            <li key={index} className="nav-item mx-2" onClick={() => navigate(item.path)}>
              {item.icon}
            </li>
          ))}
        </ul>
      </div>
      <Tooltip id="tooltip" place="bottom" effect="solid" />
    </nav>
  );
}

export default Navbar;
